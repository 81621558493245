import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularIndeterminate() {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#f0f0f0", 
                color: "linear-gradient(to bottom, #fce001, #fdb813)"
            }}
        >
            <CircularProgress
                size={70} 
                thickness={2} 
                sx={{
                    color: "#fdb813"
                }}
            />
        </Box>
    );
}
