import "../styles/Footer.css";
import { Link } from "react-router-dom";
import img12 from "../Assist/img/image (12).png";
import { MdEmail } from "react-icons/md";
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Optional for smooth scrolling
  });
};
function Footer() {
  return (
    <div>
      <div className="footer-wrapper">
        <div className="Footer-main">
          <div className="for-footer-logo">
            <img
              src="https://res.cloudinary.com/duubabjk7/image/upload/v1715253815/tp-Imgs/logo/Footer-logo_hyzuc1.png"
              alt=""
              loading="lazy"
            />
          </div>
          <div className="footer-info">
            <h1>Services</h1>
            <Link to="/taxi-stand" onClick={scrollToTop}>
              Taxi Stand
            </Link>
            <Link to="/pool-ride" onClick={scrollToTop}>
              Pool Ride
            </Link>
            <Link to="/delivery" onClick={scrollToTop}>
              Delivery
            </Link>
            <Link to="/logistic" onClick={scrollToTop}>
              Logistic
            </Link>
          </div>
          <div className="footer-info">
            <h1>Traveling Partner</h1>
            <Link to="/privacy-policy" onClick={scrollToTop}>
              Privacy Policy
            </Link>
            <Link to="/terms-conditions" onClick={scrollToTop}>
              Terms & Conditions
            </Link>
            <div className="footer-info">
              <h1>Contact</h1>
              <div className="phone-nbr">
                {/* <img src={phone} alt="" /> */}
                {/* <p>021-12347</p> */}
              </div>
              <div className="phone-nbr">
                <Link to="https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=164&ct=1731066258&rver=7.5.2211.0&wp=MBI_SSL&wreply=https%3a%2f%2foutlook.live.com%2fowa%2f%3fnlp%3d1%26cobrandid%3dab0455a0-8d03-46b9-b18b-df2f57b9e44c%26culture%3den-us%26country%3dus%26RpsCsrfState%3db9784206-8fe9-e250-9a38-58c4cf093461&id=292841&aadredir=1&CBCXT=out&lw=1&fl=dob%2cflname%2cwld&cobrandid=ab0455a0-8d03-46b9-b18b-df2f57b9e44c">
                  <MdEmail />
                  <p>info@traveling-partner.com</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-info">
            <h1>Download</h1>
            <div className="footer-info-img">
              <Link to="https://play.google.com/store/apps?hl=en&gl=US">
                <img
                  src="https://res.cloudinary.com/duubabjk7/image/upload/v1715253809/tp-Imgs/img/google_wy7mc6.png"
                  alt="Google-PlayStore"
                  loading="lazy"
                />
              </Link>
              <Link to="https://www.apple.com/app-store/">
                <img
                  src="https://res.cloudinary.com/duubabjk7/image/upload/v1715253812/tp-Imgs/img/iso_imftsl.png"
                  alt="App-Store"
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
          <div className="footer-info-img">
            <div className="download-img">
              {/* <a
                href="https://profiles.dunsregistered.com/DnBSAMECompProfile.aspx?PaArea=Email&SealkeyQ=E95213184044"
                target="_blank"
                rel="noopener noreferrer"
              > */}
                <img src={img12} alt="" />
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="blac-border-b">
        <p>Copyright © 2023 Travelpartner All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
