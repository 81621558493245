import React from "react";
import "../styles/Privacy.css";
import { Link } from "react-router-dom";

import privacyImage from "../Assist/img/Frame 71.png";

const Terms = () => {
  return (
    <div className="privacy-policy">
      <header className="header-privacy">
        <div className="header-left">
          <h1>
            Terms and Conditions for
            <span className="color-forp"> Travel Partner</span>{" "}
          </h1>
          <p>
            Welcome to Travel Partner! These Terms and Conditions govern your
            use of our services as a Driver or a Partner. Please read this
            document carefully as it outlines the terms under which you may
            access and utilize our platform. By using our services, you agree to
            comply with and be bound by these Terms and Conditions
          </p>
          <Link to="/" className="back-link">
            ← Back to Home
          </Link>
        </div>
        <div className="header-right">
          <img
            src={privacyImage}
            alt="Privacy illustration"
            className="privacy-image"
          />
        </div>
      </header>

      <main className="main-content">
        {/* section 1*/}
        <section className="policy-section">
          <h2>Definitions</h2>
          <ul>
            <li>
              {" "}
              <strong>Travel Partner:</strong>
            </li>

            <p>
              refers to our platform and mobile application that enables Drivers
              to provide Delivery Services, Pool Rides, logistics services, and
              Trip planning services to Partners within and between cities.
            </p>
            <li>
              {" "}
              <strong>Driver:</strong>
            </li>

            <p>
              refers to individuals who offer transportation, delivery,
              logistics, or trip-planning services to Partners using the Travel
              Partner platform.
            </p>
            <li>
              {" "}
              <strong>Partner:</strong>
            </li>
            <p>
              refers to individuals who use the Travel Partner platform to book
              rides, deliveries, logistics services, or plan trips.
            </p>
          </ul>
        </section>
        <section className="policy-section">
          <ul>
            <h2>Disclaimers</h2>

            <li>
              Travel Partner does not guarantee the availability of Drivers,
              trip planning services, or the accuracy of ride or delivery times.
            </li>
            <li>
              Travel Partner is not responsible for any goods or packages
              transported through our platform.
            </li>
          </ul>
        </section>
        {/* section 2*/}

        {/* section2 */}

        <section className="policy-section">
          <h2>Registration and Accounts</h2>
          <ul>
            <li>
              {" "}
              To access and use our services, you must create an account and
              provide accurate, current, and complete information. You are
              responsible for maintaining the confidentiality of your account
              information and for all activities that occur under your account.
            </li>
          </ul>
        </section>
        {/* section 3*/}

        {/* section 4*/}

        <section className="policy-section ">
          <h2>Payment</h2>
          <ul>
            <li>
              {" "}
              Payment for services is processed through our platform. By using
              our services, you agree to the payment terms outlined in the app,
              including the cost of services, payment methods, and any
              applicable fees.
            </li>
          </ul>
        </section>

        <section className="policy-section ">
          <h2>Privacy</h2>
          <ul>
            <li>
              Your use of Travel Partner is also governed by our Privacy Policy,
              which outlines how we collect, use, and protect your data. By
              using our services, you consent to the practices outlined in the
              Privacy Policy.
            </li>
          </ul>
        </section>
        {/* section8 */}

        <section className="policy-section ">
          <h2>Termination</h2>
          <ul>
            <li>
              {" "}
              We reserve the right to terminate or suspend your account and
              access to our services at our discretion, without notice, for any
              violation of these Terms and Conditions.
            </li>
          </ul>
        </section>
        {/* section9 */}
        <section className="policy-section ">
          <h2>Limitation of Liability</h2>
          <ul>
            <li>
              {" "}
              Travel Partner and its affiliates are not liable for any indirect,
              incidental, special, consequential, or punitive damages, or any
              loss of profits or revenues, whether incurred directly or
              indirectly, or any loss of data, use, goodwill, or other
              intangible losses.
            </li>
          </ul>
        </section>
        {/* section10 */}

        <section className="policy-section ">
          <h2>Changes to Terms and Conditions </h2>
          <ul>
            <li>
              {" "}
              We may update these Terms and Conditions from time to time to
              reflect changes in our practices, legal requirements, or
              operational needs. Continued use of our services after such
              changes implies your consent to the revised Terms and Conditions.
            </li>
          </ul>
        </section>
        {/* section11 */}

        <section className="policy-section ">
          <h2>Contact Us</h2>
          <ul>
            <li>
              For questions, concerns, or inquiries related to these Terms and
              Conditions, please contact us at ( Your Contact Information ).
              <br />
              Thank you for choosing Travel Partner. We are dedicated to
              streamlining transportation, logistics, and trip planning while
              ensuring a secure and efficient experience for both Drivers and
              Partners.
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default Terms;
