import React, { useState, useEffect } from 'react';
import { IoCloudUploadOutline } from 'react-icons/io5';
import axios from 'axios';
import FormAlert from './FormAlert'; // Import the FormAlert component
import CircularIndeterminate from './loader'; // Import the loader component
import BasicModal from './Modal'; // Import the BasicModal component

const ContactUsForm = () => {
  const [submissionStatus, setSubmissionStatus] = useState(null); // Add a state to track submission status
  const [alertVisible, setAlertVisible] = useState(false); // Add a state to track alert visibility
  const [selectedFile, setSelectedFile] = useState(null); // State to store selected file name
  const [file, setFile] = useState(null); // State to store the selected file object
  const [filePreview, setFilePreview] = useState(null); // State to store Base64 preview of the file
  const [loading, setLoading] = useState(false); // State to track loading status
  const [open, setOpen] = useState(false); // State to manage modal open/close
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const submitHandler = (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when form submission starts

    const name = event.target.Name.value;
    const subject = event.target.subject.value;
    const email = event.target.email.value;
    const message = event.target.message.value;
    const phoneNumber = event.target.phoneNumber.value;

    const formData = new FormData(); // Create a form data object
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);
    formData.append('subject', subject);
    formData.append('phoneNumber', phoneNumber);
    if (file) {
      formData.append('photo', file); // Append the selected file to the form data object
    }

    axios
      .post('https://api.traveling-partner.com/api/contact', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      })
      .then((response) => {
        console.log(response);
        setSubmissionStatus('success');
        setAlertVisible(true);
        setLoading(false); // Set loading to false when form submission is successful
      })
      .catch((error) => {
        console.log(error);
        setSubmissionStatus('error');
        setAlertVisible(true);
        setLoading(false); // Set loading to false when form submission fails
      });
  };

  useEffect(() => {
    if (alertVisible) {
      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
    }
  }, [alertVisible]);

  const handleUploadClick = () => {
    // Trigger file input click
    document.getElementById('photo').click();
  };

  const handleFileChange = (event) => {
    // Update selected file name state
    setSelectedFile(event.target.files[0].name);
    setFile(event.target.files[0]); // Update the file state with the selected file object

    // Generate preview of the selected file
    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result); // Set Base64 preview of the file
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className="form-main-div">
      <div className="LOADER-DIV">{loading && <CircularIndeterminate />}</div>

      <form onSubmit={submitHandler}>
        <div className="first-name-last">
          <input type="text" name="Name" placeholder="Name" required />
          <input type="text" name="subject" placeholder="subject" required />
        </div>
        <div className="first-name-last">
          <input type="email" name="email" placeholder="Email" required />
          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            inputMode="numeric"
            pattern="[0-9]*"
            required
          />
        </div>
        <div className="text-msg">
          <textarea name="message" placeholder="Message" required />
        </div>
        <div className="contac-us-btn-img">
          <div className="for-upload-img">
            {/* Handle click on button directly */}
            <button type="button" onClick={handleUploadClick}>
              <IoCloudUploadOutline />
              <p style={{ padding: '0px 5px' }}>Upload</p>
            </button>
            {/* File input stays hidden */}
            <input
              className="Add-blog-img"
              type="file"
              id="photo"
              name="photo"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileChange} // Handle file selection
            />
            {/* Display selected file name */}
          </div>
          <div className="contact-us-btn">
            <button type="submit">Submit</button>
          </div>
        </div>
        <div className="upload-file-name">
          {selectedFile && <p>Selected File: {selectedFile}</p>}
          {filePreview && (
            <img
              src={filePreview}
              alt="Preview"
              style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
              onClick={handleOpen}
            />
          )}
        </div>
      </form>
      {alertVisible && <FormAlert status={submissionStatus} />}
      <BasicModal imgSrc={filePreview} open={open} handleClose={handleClose} />
    </div>
  );
};

export default ContactUsForm;
