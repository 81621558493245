import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import "../styles/FormAlert.css"

export default function FormAlert({ status }) {
  if (status === 'success') {
    return (
      <div className="alert-container">
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            Form submitted successfully!
          </Alert>
        </Stack>
      </div>
    );
  } else if (status === 'error') {
    return (
      <div className="alert-container">
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="error">
            Error submitting form. Please try again (Network Error)
          </Alert>
        </Stack>
      </div>
    );
  } else {
    return null; // Return null if submissionStatus is null
  }
}