import React from "react";
import "../styles/Privacy.css";
import { Link } from "react-router-dom";
import privacyImage from "../Assist/img/Frame 72.png";

const Privacy = () => {
  return (
    <div className="privacy-policy">
      <header className="header-privacy">
        <div className="header-left">
          <h1>
            Privacy Policy for <br />
            <span className="color-forp">Travel Partner</span>
          </h1>
          <p>
            Welcome to Travel Partner, where your privacy and security are
            central to our commitment. We are dedicated to safeguarding your
            personal information while providing you with a seamless experience
            using our diverse services. Whether you're a Driver or a Partner,
            this Privacy Policy serves as your comprehensive guide to how we
            collect, utilize, disclose, and protect your data. By utilizing our
            services, you explicitly agree to the principles outlined in this
            policy.
          </p>
          <Link to="/" className="back-link">
            ← Back to Home
          </Link>
        </div>
        <div className="header-right">
          <img
            src={privacyImage}
            alt="Privacy illustration"
            className="privacy-image"
          />
        </div>
      </header>
      <main className="main-content">
        <section className="policy-section section1">
          <h2>Information We Collect</h2>
          <ul>
            <li>
              To deliver a personalized and efficient experience, we collect
              various types of information:
            </li>
            <li>
              {" "}
              <strong>Personal Information:</strong>
            </li>
            <p>
              This encompasses your name, contact details, payment information,
              and, if required, identification documents to comply with local
              regulations.
            </p>
            <li>
              <strong>Location Data:</strong>
            </li>
            <p>
              We rely on your location to match you swiftly with nearby ride
              requests, facilitating convenience for Partners and helping
              Drivers optimize routes. You have the option to disable location
              tracking but bear in mind that this may affect certain
              functionalities.
            </p>
            <li>
              <strong>Transaction Data:</strong>
            </li>
            <p>
              We gather information related to your ride bookings, payments, and
              comprehensive trip history, which helps us improve and customize
              your experience.
            </p>
          </ul>
        </section>

        <section className="policy-section section2">
          <h2>How We Use Your Information</h2>
          <ul>
            <li>
              Your data plays a pivotal role in enhancing your Travel Partner
              experience:
            </li>
            <li>
              {" "}
              <strong>Providing Services: </strong>
            </li>
            <p>
              Your information is critical for facilitating ride bookings,
              delivery services, logistics, and fuel cost savings within and
              between cities.
            </p>
            <li>
              <strong>Payment Processing: </strong>
            </li>
            <p>
              We utilize your data for secure and transparent financial
              transactions, ensuring your peace of mind with each payment.
            </p>
            <li>
              <strong>Improving Services: </strong>
            </li>
            <p>
              We continually enhance our services based on your interactions,
              develop innovative features, and personalize your experience to
              better serve your unique needs.
            </p>
            <li>
              {" "}
              <strong>Safety and Security: </strong>
            </li>
            <p>
              Our unwavering commitment to safety includes an innovative feature
              that enables rides to be provided on behalf of a specific gender,
              ensuring a comfortable and secure journey for all.
            </p>
          </ul>
        </section>
        <section className="policy-section section3">
          <h2>Data Sharing</h2>
          <ul>
            <li>
              To provide you with a seamless experience, we may share your
              information with specific parties:
            </li>
            <li>
              <strong>Drivers: </strong>
            </li>
            <p>
              Sharing information with drivers is essential to facilitate ride
              bookings, delivery services, and logistics, ensuring efficient
              transportation.
            </p>
            <li>
              {" "}
              <strong>Payment Processors: </strong>
            </li>
            <p>
              Your data is used by payment processors to securely process
              payments, adding a layer of transparency to all financial
              transactions.
            </p>
            <li>
              {" "}
              <strong>Third-party Service Providers:</strong>
            </li>
            <p>
              Our services integrate with essential third-party APIs, Maps,
              Twilio, AWS S3 buckets, and other components crucial to our
              operations.
            </p>
            <li>
              {" "}
              <strong>Law Enforcement: </strong>
            </li>
            <p>
              We may share information when required by law or to protect our
              rights, safety, and security.
            </p>
          </ul>
        </section>
        <section className="policy-section section4">
          <h2>Data Security</h2>
          <ul>
            <li>
              We take the security of your data seriously and employ
              industry-standard measures to protect it from unauthorized access,
              disclosure, alteration, or destruction. Our encryption protocols
              and rigorous security practices are in place to ensure your
              information remains safe.
            </li>
          </ul>
        </section>

        <section className="policy-section section5">
          <h2>Your Choices</h2>
          <ul>
            <li>Your control is paramount:</li>
            <li>
              {" "}
              <strong>Review and Update:</strong>
            </li>
            <p>
              You have the power to manage your personal information in your
              account settings, ensuring it remains current and accurate.
            </p>
            <li>
              {" "}
              <strong>Location Tracking:</strong>
            </li>
            <p>
              The choice is yours. Option to enable or disable location tracking
              to suit your preferences and needs.
            </p>
          </ul>
        </section>

        <section className="policy-section section6">
          <h2>Third-party Links</h2>
          <ul>
            <li>
              {" "}
              Please note that our services may include links to third-party
              websites or services. These entities have their privacy practices,
              separate from ours. As such, we encourage you to review their
              privacy policies when using their services.
            </li>
          </ul>
        </section>

        <section className="policy-section section7">
          <h2>Changes to this Policy</h2>
          <ul>
            <li>
              {" "}
              To keep you informed and updated, we may periodically update this
              Privacy Policy to reflect changes in our practices, adhere to
              evolving legal requirements, or address operational needs. Rest
              assured, you will receive notifications of significant changes.
            </li>
          </ul>
        </section>
        <section className="policy-section section10">
          <h2>Contact Us</h2>
          <ul>
            <li>
              {" "}
              For questions, concerns, or inquiries related to these Terms and
              Conditions, please contact us at (Your Contact Information).
              <br />
              Thank you for choosing Travel Partner. We are dedicated to
              streamlining transportation, logistics, and trip planning while
              ensuring a secure and efficient experience for both Drivers and
              Partners.
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default Privacy;
