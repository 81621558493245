import { Link } from "react-router-dom"
import circley from "../Assist/Taxi-stand-img/trip-circle-img.png"
import Gplaystore from "../Assist/img/google.png"
import Iplaystore from "../Assist/img/iso.png"
import enjoyImg from "../Assist/Taxi-stand-img/image 81 (1).png"
import BookRide from "../Assist/Taxi-stand-img/Book a Ride.png"
import TrackYou from "../Assist/Taxi-stand-img/Track your cab.png"
import ArriveSafely from "../Assist/Taxi-stand-img/Arrive safely.png"
import "../styles/Trip.css"

function Trip() {
    return (
        <div>
            <div className="trip-cover">
                <div className="trip-text">
                    <h3> <span>Trip</span></h3>
                    <h4 style={{ fontSize: "20px", fontWeight: "500", paddingTop: "10px" }}>Organize Trips with No Added Commissions</h4>
                    <p>Experience stress-free trip planning for family, friends, or school outings with Traveling
                        Partner. Our platform, known for its commission-free and self-negotiation nature,
                        eliminates the hassle of navigating multiple locations or dealing with different trip drivers
                        and prices. With just a few clicks, users can efficiently select their trip driver, ensuring a
                        seamless and time-saving trip planning experience. Trust Traveling Partner for a
                        community-driven approach to convenient trip coordination.</p>

                    <div className="taxi-playstore-img">
                        <div className="tax-playstore-img-inner">
                            <Link to="https://play.google.com/store/apps?hl=en&gl=US">  <img src={Gplaystore} alt="Google-PlayStore" /><br /></Link>
                        </div>
                        <div className="taxi-playstore-img-inner">
                            <Link to="https://www.apple.com/app-store/"><img src={Iplaystore} alt="App-Store" /></Link>
                        </div>
                    </div>
                </div>


                <div className="trip-img-inner">
                    <img src={circley} alt="" />
                </div>
            </div>
            {/* Enjoy your weekend with Travel partner */}
            <div className="Enjoy-weekend-main">
                <div className="Enjoy-weekend-text">
                    <h2>Enjoy your weekend with Travel partner</h2>
                    <p>Go on a long trip with your friends and family Get a Taxi That is Just
                        Made for Road Trips Freedom from Car Servicing Hassles Optimize Travelling Time</p>
                </div>
                <div className="Enjoy-weekend-img">
                    <img src={enjoyImg} alt="" />
                </div>
            </div>

            {/* Reliable , Safe , Transparent */}

            <div className="Reliable-Safe-cover">
                <h2>Reliable, Safe, Transparent</h2>
                <p>Your trusted ride for every occasion!</p>
                <div className="safe-playstore-img">
                    <Link to="https://play.google.com/store/apps?hl=en&gl=US">
                        <img src={Gplaystore} alt="Google-PlayStore" />
                    </Link>
                    <Link to="https://www.apple.com/app-store/">
                        <img src={Iplaystore} alt="App-Store" />
                    </Link>
                </div>
            </div>
            {/* Best Things Of Travel partner */}

            <div className="How-its-work-main">
                <div className="How-its-work-main-text">
                    <h1>How its work</h1>
                </div>
                <div className="How-its-work-box">
                    <div className="How-its-work-box1">
                        <div className="How-its-work-card">
                            <img src={BookRide} alt="" />
                            <h3>Book a Ride</h3>
                            <p>Initiate your journey by booking a ride through Traveling Partner. Whether it's a family
                                outing, a trip with friends, or a school excursion, easily connect with reliable trip drivers
                                on our platform. </p>
                        </div>
                    </div>
                    <div className="How-its-work-box2">
                        <div className="How-its-work-card">
                            <img src={TrackYou} alt="" />
                            <h3>Track Your Vehicle:</h3>
                            <p>Enjoy peace of mind during your trip with real-time vehicle tracking. Our user-friendly
                                app allows you to monitor your vehicle's location, ensuring transparency and security
                                throughout the journey. </p>
                        </div>
                    </div>
                    <div className="How-its-work-box3">
                        <div className="How-its-work-card">
                            <img src={ArriveSafely} alt="" />
                            <h3>Arrive safely</h3>
                            <p>With Traveling Partner, safety is a shared responsibility. Arrive at your destination with
                                the flexibility of self-negotiable arrangements, as our commission-free app empowers
                                users to manage their travel experience independently.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default Trip
