import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { MdCancelPresentation } from "react-icons/md";
import "../styles/Modal.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal({ imgSrc, open, handleClose }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Button onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <MdCancelPresentation 
                    style={{
                        color:"red",
                        fontSize:"20px",
                        marginTop:"-10px"
                    }}
                    />
                </Button>
                <Box
                    component="img"
                    src={imgSrc}
                    alt="Preview"
                    sx={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '90vh',
                        display: 'block',
                        margin: '0 auto'
                    }}
                />
            </Box>
        </Modal>
    );
}
