import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Home from "./Components/Home";
import TaxiStand from "./Components/TaxiStand";
import PoolRide from "./Components/PoolRide";
import Delivery from "./Components/Delivery";
import Logistic from "./Components/Logistic";
import AboutUS from "./Components/AboutUs";
import SignUp from "./Components/signUp";
import Navbar from "./Components/Navbar";
import Trip from "./Components/Trip";
import Blog from "./Components/Blog";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";
import Footer from "./Components/Footer";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          {/* <Route exact path="/" element={<ComingSoon />} /> */}

          <Route exact path="/" element={<Home />} />

          <Route path="/taxi-stand" element={<TaxiStand />} />
          <Route path="/pool-ride" element={<PoolRide />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/logistic" element={<Logistic />} />
          <Route path="/trip" element={<Trip />} />
          <Route path="/about-us" element={<AboutUS />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-conditions" element={<Terms/>} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/sign Up" element={<SignUp />} />
        </Routes>
        < Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
