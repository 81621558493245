import { Link } from "react-router-dom";
import "../styles/Delivery.css";
import { FaArrowRightLong } from "react-icons/fa6";

function Delivery() {
  return (
    <div>
      <div className="taxi-stand-cover ">
        <div className="texi-text">
          <h3>
            {" "}
            <span>Delivery</span>
          </h3>
          <h4
            style={{ fontSize: "20px", fontWeight: "500", paddingTop: "10px" }}
          >
            Connecting Senders and Couriers Fee-Free
          </h4>
          <p>
            Sending and receiving items has never been easier with our Delivery
            service. We provide a commission-free platform for users to directly
            connect with delivery services. This feature fosters a collaborative
            environment, encouraging senders and couriers to negotiate their own
            terms without extra fees, ensuring flexibility and cost-effective
            item transfer.{" "}
          </p>
          <div className="playstore-img">
            <div className="playstore-img-inner">
              <Link to="https://play.google.com/store/apps?hl=en&gl=US">
                {" "}
                <img src="https://res.cloudinary.com/duubabjk7/image/upload/v1715253809/tp-Imgs/img/google_wy7mc6.png" alt="Google-PlayStore"   loading="lazy"
/>
                <br />
              </Link>
            </div>
            <div className="playstore-img-inner">
              <Link to="https://www.apple.com/app-store/">
                <img src="https://res.cloudinary.com/duubabjk7/image/upload/v1715253812/tp-Imgs/img/iso_imftsl.png" alt="App-Store"  loading="lazy"
 />
              </Link>
            </div>
          </div>
        </div>

        <div className="taxi-img-inner">
          <img src="https://res.cloudinary.com/duubabjk7/image/upload/v1715253509/tp-Imgs/Taxi-stand-img/Delivery-img1_b13eli.png" alt=""  loading="lazy"
 />
        </div>
      </div>

      <div className="Deliver-your-Treasure-main ">
        <div className="main-text-treasure">
          <h1>Deliver your Treasure with the best Service</h1>
        </div>
        {/* <div className="Convenient-door-text1">
                    <h3>Convenient door pick-up. door delivery and door to door value added services   </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                    <span>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</span>
                </div> */}
        <div className="Convenient-door-main">
          <div className="Convenient-door-pic">
            <img src="https://res.cloudinary.com/duubabjk7/image/upload/v1715253516/tp-Imgs/Taxi-stand-img/Treasure_tafup9.png" alt=""   loading="lazy"
/>
          </div>
          <div className="Convenient-door-text">
            {/* <h3>Convenient door pick-up. door delivery and door to door value added services   </h3>
                        <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        <span>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</span> */}
            <DeliveryTreasure />
          </div>
        </div>
      </div>
      <div className="why-choose-us-main">
        <div className="why-choose-inner">
          <h3>WHY CHOOSE US?</h3>
          <span>We are Fast, Affordable and Reliable</span>
          <div className="arrow-text1">
            <div className="arrow-text2">
              <FaArrowRightLong />
              <p>Experienced Couriers</p>
            </div>
            <div className="arrow-text2">
              <FaArrowRightLong />

              <p>Affordable</p>
            </div>
          </div>
          <div className="arrow-text1">
            <div className="arrow-text2">
              <FaArrowRightLong />
              <p>Expert Customer Service</p>
            </div>
            <div className="arrow-text2">
              <FaArrowRightLong />
              <p>Save Time </p>
            </div>
          </div>
        </div>
      </div>
      <div className="How-Delivery-W">
        <div className="How-Delivery-img">
          <img src="https://res.cloudinary.com/duubabjk7/image/upload/v1715253508/tp-Imgs/Taxi-stand-img/home-page-b-img_l1ekvj.png" alt=""   loading="lazy"
 />
        </div>
        <div className="How-Delivery-text">
          <div className="ol-list">
            <h3>How Delivery works </h3>
            <NumberedList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Delivery;

const NumberedList = () => {
  const textArray = [
    "Download the app",
    <span style={{ fontSize: "20px", color: "black" }}>
      Provide delivery details{" "}
      <span style={{ fontSize: "16px", color: "#747474", marginLeft: "10px" }}>
        (date, time, stuff, pickup location, drop off location, Contact no.)
      </span>
    </span>,
    <span style={{ fontSize: "20px", color: "black" }}>
      Choose rider{" "}
      <span style={{ fontSize: "16px", color: "#747474" }}>
        ( Male or Female)
      </span>
    </span>,
    "Confirm rider",
    "Driver will reach to your pickup location in the given time.s",
  ];

  return (
    <div className="numberedList">
      <ul>
        {textArray.map((text, index) => (
          <li key={index}>
            <span className="dot" />
            <span className="text">{text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const DeliveryTreasure = () => {
  const content = [
    "Hassle-Free Sending: Say goodbye to the complexities of traditional delivery services.Traveling Partner makes sending your items a breeze.",
    "Reliable Couriers: Our platform connects you with trusted couriers who will handle your treasures with care and professionalism.",
    "Cost-Effective: Traveling Partner's commission-free approach means you don't have to worry about extra charges eating into your budget.",
    "Track Your Package: Real-time tracking allows you to monitor your package's journey, providing peace of mind and transparency.",
    "Community-Centric: We foster a community of support, allowing you to connect with reliable couriers and fellow users.",
  ];

  return (
    <div className="shipping-container">
      <div className="shipping-content">
        {content.map((line, index) => {
          const [beforeColon, afterColon] = line.split(":");
          return (
            <p key={index}>
              <strong>{beforeColon}</strong>: {afterColon}
            </p>
          );
        })}
      </div>
    </div>
  );
};
