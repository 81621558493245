import "../styles/TaxiStand.css";
import circley from "../Assist/Taxi-stand-img/Image (1).png";
import Gplaystore from "../Assist/img/google.png";
import Iplaystore from "../Assist/img/iso.png";
import { Link } from "react-router-dom";
import Activate from "../Assist/Taxi-stand-img/Activate-file.png";
import Register from "../Assist/Taxi-stand-img/Register-file.png";
import download from "../Assist/Taxi-stand-img/download-file.png";
import Driveimg from "../Assist/Taxi-stand-img/image 61.png";
import Driveimg2 from "../Assist/Taxi-stand-img/image 61.png";
import Right_Arrow from "../Assist/Taxi-stand-img/Up Arrow.png";

function TaxiStand() {
  return (
    <div>
      <div className="taxi-stand-cover ">
        <div className="texi-text">
          <h3>
            {" "}
            <span>Taxi Stand</span>
          </h3>
          <div className="poll-ride-h4">
            {" "}
            <h4> Connect and Commute Commission-Free</h4>
          </div>
          <p>
            Traveling Partner presents a modern platform connecting users to
            diverse taxi stands across Pakistan. Say goodbye to added fees when
            you access this hassle-free, commission-free service. Our platform
            ensures an affordable, direct connection with various taxi stands,
            allowing you to negotiate fares at your convenience and make your
            commuting experience both smooth and cost-effective.
          </p>
          <div className="playstore-img">
            <div className="playstore-img-inner">
              <Link to="https://play.google.com/store/apps?hl=en&gl=US">
                {" "}
                <img src={Gplaystore} alt="Google-PlayStore" />
                <br />
              </Link>
            </div>
            <div className="playstore-img-inner">
              <Link to="https://www.apple.com/app-store/">
                <img src={Iplaystore} alt="App-Store" />
              </Link>
            </div>
          </div>
        </div>

        <div className="taxi-img-inner">
          <img src={circley} alt="" />
        </div>
      </div>
      <div className="For-Drivers-main">
        <div className="For-Drivers-text">
          <h2>For Drivers</h2>
          <p>Ready to Earn with Us?</p>
        </div>
        <div className="Drivers-card-main">
          <div className="driver-box ">
            <div className="driver-box-main-img">
              <img src={download} alt="" />
            </div>
            <h3>Download</h3>
            <p>
              Begin your journey by downloading the Traveling Prtner App from
              your app store. Gain instant access to a world of convenient
              transportation and collaboration.
            </p>
          </div>
          <div className="driver-box">
            <div className="driver-box-main-img">
              <img src={Register} alt="" />
            </div>
            <h3>Register</h3>
            <p>
              Register now! by verifying your mobile number to unlock exclusive
              benefits. Experience a smooth registration process for a secure
              and verified driver account.
            </p>
          </div>
          <div className="driver-box1">
            <div className="driver-box-main-img1">
              <img src={Activate} alt="" />
            </div>
            <h3>Activate</h3>
            <p>
              Complete your profile with your personal details and required
              documents. Enhance trust and visibility within the community while
              enjoying personalized features designed just for you
            </p>
          </div>
        </div>
        <div className="playstore-img1">
          <div className="playstore-img-inner1">
            <Link to="https://play.google.com/store/apps?hl=en&gl=US">
              {" "}
              <img src={Gplaystore} alt="Google-PlayStore" />
              <br />
            </Link>
          </div>
          <div className="playstore-img-inner1">
            <Link to="https://www.apple.com/app-store/">
              <img src={Iplaystore} alt="App-Store" />
            </Link>
          </div>
        </div>
      </div>
      <div className="Drive-Travelpartner-main">
        <div className="Drive-Travelpartner-img">
          <img src={Driveimg} alt="" />
        </div>
        <div className="Drive-Travelpartner-text">
          <h2>
            Drive with Travelpartner &<br /> Earn Money
          </h2>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Drive and earn on your terms.</p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>No additional fees to join or start earning.</p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Work when it suits you best </p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Complete autonomy to decide on fares.</p>
          </div>

          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Effortlessly manage your rides through the app.</p>
          </div>
          <div className="drive-trv-btn">
            <Link to="https://play.google.com/store/apps?hl=en&gl=US">
              {" "}
              <button>Register Now </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="For-Drivers-main">
        <div className="For-Drivers-text">
          <h2>For partners</h2>
          <p>Explore the Awesomeness!</p>
        </div>
        <div className="Drivers-card-main">
          <div className="driver-box">
            <div className="driver-box-main-img">
              <img src={download} alt="" />
            </div>
            <h3>Download</h3>
            <p>
              Download our app now to seamlessly access a world of convenient
              and affordable travel. Your next journey is just a tap away!
            </p>
          </div>
          <div className="driver-box">
            <div className="driver-box-main-img">
              <img src={Register} alt="" />
            </div>
            <h3>Register</h3>
            <p>
              Join the Traveling Prtner community swiftly. Register, verify your
              mobile number, and unlock a world of convenience.
            </p>
          </div>
          <div className="driver-box1">
            <div className="driver-box-main-img1 extra-style">
              <img src={Activate} alt="" />
            </div>
            <h3>Activate</h3>
            <p>
              Activate your account by providing your necessary personal
              details. Enjoy personalized services, enhanced security, and a
              more connected journey.
            </p>
          </div>
        </div>
        <div className="playstore-img1">
          <div className="playstore-img-inner1">
            <Link to="https://play.google.com/store/apps?hl=en&gl=US">
              {" "}
              <img src={Gplaystore} alt="Google-PlayStore" />
              <br />
            </Link>
          </div>
          <div className="playstore-img-inner1">
            <Link to="https://www.apple.com/app-store/">
              <img src={Iplaystore} alt="App-Store" />
            </Link>
          </div>
        </div>
      </div>
      <div className="Drive-Travelpartner-main">
        <div className="Drive-Travelpartner-text">
          <h2>
            Benefits of <br /> Travelpartner App
          </h2>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Connect with passengers without added fees.</p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Independently negotiate and finalize fares.</p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p> Foster a collaborative and transparent environment.</p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Plan and execute your travel in your preferred style</p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Receive positive reviews, enhancing your stand's reputation.</p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Streamline operations with the app's user-friendly features.</p>
          </div>
          <div className="for-arrow">
            <img src={Right_Arrow} alt="" />
            <p>Freedom to choose your travel companions.</p>
          </div>
          <div className="drive-trv-btn">
            <Link to="https://play.google.com/store/apps?hl=en&gl=US">
              <button>Download the app </button>
            </Link>
          </div>
        </div>
        <div className="Drive-Travelpartner-img">
          <img src={Driveimg2} alt="" />
        </div>
      </div>
    </div>
  );
}

export default TaxiStand;
