import { NavLink } from "react-router-dom";
import { useState } from "react";
import Logo from "../Assist/logo/Logo (1).png";
import "../styles/Navbar.css"

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false);

    const handleShowNavbar = () => {
        setShowNavbar(false); // Close the navbar when any link is clicked
    };

    const toggleNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    return (
        <nav className="navbar">
            <div className="container">
                <div className="logo">
                    <NavLink to="/" onClick={handleShowNavbar}>

                        <img src={Logo} alt=""    loading="lazy"
/>
                    </NavLink>
                </div>
                <div className="menu-icon" onClick={toggleNavbar}>
                    <Hamburger />
                </div>
                <div className={`nav-elements ${showNavbar && "active"}`}>
                    <ul>
                        <li>
                            <NavLink to="/" onClick={handleShowNavbar}>
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/taxi-stand" onClick={handleShowNavbar}>
                                Taxi Stand
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/pool-ride" onClick={handleShowNavbar}>Pool Ride</NavLink>
                        </li>
                        <li>
                            <NavLink to="/delivery" onClick={handleShowNavbar}>Delivery</NavLink>
                        </li>
                        <li>
                            <NavLink to="/logistic" onClick={handleShowNavbar}>Logistic</NavLink>
                        </li>
                        <li>
                            <NavLink to="/trip" onClick={handleShowNavbar}>Trip </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about-us" onClick={handleShowNavbar}>About Us </NavLink>

                        </li>
                        {/* <li>
                            <NavLink to="/privacy" onClick={handleShowNavbar}>Privacy </NavLink>

                        </li> */}
                        {/* <li>
                            <NavLink to="/terms" onClick={handleShowNavbar}>Terms </NavLink>

                        </li> */}

                        {/* <li style={{ padding: "5px 20px", background: "white", marginTop: "-5px" }}>
                            <NavLink to="/signUp">sign Up</NavLink>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;

const Hamburger = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="24"
        viewBox="0 0 52 24"
    >
        <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
            <rect
                id="Rectangle_3"
                data-name="Rectangle 3"
                width="42"
                height="4"
                rx="2"
                transform="translate(304 47)"
                fill="#574c4c"
            />
            <rect
                id="Rectangle_5"
                data-name="Rectangle 5"
                width="42"
                height="4"
                rx="2"
                transform="translate(304 67)"
                fill="#574c4c"
            />
            <rect
                id="Rectangle_4"
                data-name="Rectangle 4"
                width="52"
                height="4"
                rx="2"
                transform="translate(294 57)"
                fill="#574c4c"
            />
        </g>
    </svg>
);
