// import React from 'react';
import "../styles/VideoPlayer.css";
 // Import CSS file for styling

// const VideoPlayer = () => {
//   return (
//     <div className="video-player-container">
//       <video controls poster="https://res.cloudinary.com/dabxnoxsx/image/upload/v1710239883/How_to_register_as_a_Driver_a01zuj.png" className="video-player">
//         <source src="https://res.cloudinary.com/dabxnoxsx/video/upload/v1708419720/Driver_Registration_fy4in8.mp4" type="video/mp4" />
//       </video>
//     </div>
//   );
// };

// export default VideoPlayer;
import React from 'react';
// import "../styles/VideoPlayer.css";

const VideoPlayer = ({ videoSrc, posterSrc }) => {
  return (
    <div className="video-player-container">
      <video controls poster={posterSrc} className="video-player">
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoPlayer;
